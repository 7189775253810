module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"litc0de-linktree","short_name":"litc0de","start_url":"/","background_color":"#171f28","theme_color":"#171f28","display":"minimal-ui","icon":"src/images/link-variant.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e3038cfeac4e5f8d06b523d9ce42d0c9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/Users/basti/Documents/projects/linktree/src/components/layout.js"},"extensions":[".mdx"],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/basti/Documents/projects/linktree"},
    },{
      plugin: require('../node_modules/gatsby-plugin-owa/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"b9dc8e3311dee27d01acde3118a4eccf","owaUrl":"https://litcode.uber.space/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
