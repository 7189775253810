// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-protection-mdx": () => import("./../../../src/pages/data-protection.mdx" /* webpackChunkName: "component---src-pages-data-protection-mdx" */),
  "component---src-pages-imprint-mdx": () => import("./../../../src/pages/imprint.mdx" /* webpackChunkName: "component---src-pages-imprint-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */)
}

